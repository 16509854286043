
import { defineComponent, reactive, Ref, ref, toRefs } from 'vue'
import useCurrencyRecord, { ParamsInpt } from './hooks/useCurrencyRecord'
import storage, { StorageType } from '@/utils/storage'

export default defineComponent({
  setup() {
    const state = reactive({
      applicationStatusApp: null,
      exchangeOri: ''
    })
    const params: Ref<ParamsInpt> = ref({
      current: 1,
      size: 10,
      exchangeOri: '',
      applicationStatusApp: null,
      tradeAccount: storage.rcGetItem(StorageType.local, 'userInfo')
    })
    const changeParams = () => {
      params.value.applicationStatusApp = state.applicationStatusApp
      params.value.exchangeOri = state.exchangeOri
    }
    const {
      statusList,
      orientationList,
      nameList,
      accountList,
      operList,
      tableData,
      total,
      loading
    } = useCurrencyRecord(params.value)
    const handleSizeChange = (size: number) => {
      params.value.size = size
      changeParams()
    }
    const handleCurrentChange = (current: number) => {
      params.value.current = current
      changeParams()
    }
    const search = () => {
      params.value.applicationStatusApp = state.applicationStatusApp
      params.value.exchangeOri = state.exchangeOri
      params.value.current = 1
    }
    const filterColor = (val: string) => {
      switch (val) {
        case '1':
          return '#363d56'
        case '2':
          return '#ff414e'
        case '3':
          return '#1cde8a'

        default:
          break
      }
    }
    return {
      ...toRefs(state),
      params,
      filterColor,
      tableData,
      total,
      statusList,
      orientationList,
      nameList,
      accountList,
      operList,
      search,
      // reset,
      handleSizeChange,
      handleCurrentChange,
      loading
    }
  }
})
