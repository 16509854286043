import { useQuery } from '@vue/apollo-composable'
import * as currencyApi from '@/graphql/currency'
import { ref } from 'vue'

export type ParamsInpt = {
  current: number
  size: number
  exchangeOri: string | null
  applicationStatusApp: string | null
  // tradeAccount: string | null
  // deviceType: number
  // operatorAccount: string | null
  // clientName: string | null
}
type RecordType = {
  clientName: string
  exchangeOutMoney: string
  outCurrencyType: string
  exchangeInMoney: string
  inCurrencyType: string
  applicationStatus: string
  exchangeOri: string
  operatorAccount: string
  managerAccount: string
}
export default function useCurrencyRecord<T> (params: T) {
  const tableData = ref([{}])
  const total = ref(0)
  const orientationList = ref()
  const statusList = ref()
  const nameList = ref()
  const accountList = ref()
  const operList = ref()
  const { refetch, loading, onResult } = useQuery(
    currencyApi.foreignExchangeRecords,
    {
      filter: params,
      params: {
        markList: [
          'FOREIGN_EXCHANGE_ORI',
          'AO_FOREGN_EXCHANGE_APP_STATUS',
          'SEC_MONEY_TYPE'
        ]
      }
    }
  )
  onResult(res => {
    const { foreignExchangeRecords, findDictOpenAccount } = res?.data
    if (findDictOpenAccount.ok) {
      orientationList.value = [
        { name: '全部', value: '' },
        ...findDictOpenAccount.data.FOREIGN_EXCHANGE_ORI
      ]
      statusList.value = [
        { name: '全部', value: null },
        ...findDictOpenAccount.data.AO_FOREGN_EXCHANGE_APP_STATUS
      ]
      const currencyType = findDictOpenAccount.data.SEC_MONEY_TYPE
      const setType = (list: any, val: string) =>
        list.filter((item: { value: string }) => item.value === val)[0]?.name
      // 表格
      if (foreignExchangeRecords.ok) {
        tableData.value = foreignExchangeRecords?.data?.records?.map(
          (item: RecordType) => {
            return {
              outMoney:
                Number(item.exchangeOutMoney).toLocaleString() +
                setType(currencyType, item.outCurrencyType),
              inMoney:
                Number(item.exchangeInMoney).toLocaleString() +
                setType(currencyType, item.inCurrencyType),
              status: setType(statusList.value, item.applicationStatus),
              ori: setType(orientationList.value, item.exchangeOri),
              ...item
            }
          }
        )
        console.log('tableData', tableData)
        total.value = foreignExchangeRecords?.data?.total
      }
    }
    console.log('res', res)
  })
  return {
    loading,
    tableData,
    total,
    statusList,
    orientationList,
    nameList,
    accountList,
    operList,
    refetchRecord: refetch
  }
}
